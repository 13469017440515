import * as React from "react"
import Layout from "../../components/layout"
import "@fontsource/raleway/300.css"
import Hero from '../../components/hero'
import { Link } from 'gatsby'
import Header from '../../components/header'
import CharacterGrid from '../../components/charactergrid'
import InBrief from '../../components/inbrief'
import AdBanner from '../../components/adbanner'
import { StaticImage } from 'gatsby-plugin-image'
import { ImageFull } from '../../components/imageelements'
import { SynopsisAct } from '../../components/synposisstyling'
import Bibliography from '../../components/bibliography.js'
import { Helmet } from "react-helmet"
import { AdContainer } from '../../components/adstyling'
import { FunFactsHeader } from '../../components/funfactsstyling'
import { MainBodySection } from '../../components/generalstyling'
import VideoPlayer from '../../components/videoplayer'

const characters = [
  {name: 'Count Almaviva', voice: 'Tenor', desc: 'Spanish nobleman who wants Rosina to love him for who he is—variously a poor student, a drunken soldier, and a broken-record music teacher—not for his title.'},
  {name: 'Doctor Bartolo', voice: 'Baritone', desc: 'Rosina’s guardian who keeps her locked up so she can have the privilege of marrying him.'},
  {name: 'Rosina', voice: 'Soprano', desc: 'A young woman who’s in love with the Count and is much too smart to remain in thrall to Bartolo.'},
  {name: 'Figaro', voice: 'Baritone', desc: 'Sevillian barber and factotum who pulls all the strings as a matchmaker for his friends the Count and Rosina.'},
  {name: 'Don Basilio', voice: 'Bass', desc: 'Music teacher to Rosina and marriage broker to Bartolo who ultimately cares more about money than either obligation.'},
  {name: 'Berta', voice: 'Mezzo-soprano', desc: 'Bartolo’s perpetually sneezing servant whose lone aria, about old men’s desire for young wives, remains distressingly relevant 200 years later.'},
  {name: 'Fiorella', voice: 'Bass', desc: 'Almaviva’s servant who assists in his initial serenade.'},
];

const inbrief = {name: 'Il barbiere di Siviglia', 'translatedname': 'The Barber of Seville', composer: "Gioachino Rossini", librettist: "Cesare Sterbini", language: "Italian", date: 'February 20th, 1816', acts: "Two", musiclength: "Two hours, fifty minutes"};

const bibliography = [
  {name: 'The Grove Book of Operas', writer: 'Stanley Sadie & Laura Macy', url: 'https://amzn.to/3mpO243'},
  {name: 'The Life of Rossini', writer: 'Stendhal & Richard N. Coe', url: 'https://amzn.to/3EwLl6X'},
  {name: 'The Figaro Trilogy: The Barber of Seville, The Marriage of Figaro, The Guilty Mother', writer: 'Beaumarchais', url: 'https://amzn.to/3lZjmUk'},
]

// markup
const BarbierePage = () => {
  return (
    <Layout>
        <Helmet>
          <meta charSet="utf-8" />
          <title>The Barber of Seville (Il barbiere di Siviglia) | The Opera 101</title>
          <html lang="en-us" />
          <link rel="canonical" href="https://theopera101.com/operas/barbiere" />
          <meta name="description" content="A guide to Rossini’s The Barber of Seville (Il barbiere di Siviglia). Including Synopsis, Music & Arias, Fun Facts, Running Time and much more!" />
        </Helmet>
        <Hero title="The Barber of Seville" subtitle="An Opera by Gioachino Rossini"/>
        <MainBodySection>

          <p><i>The Barber of Seville</i>, or <i>Il barbiere di Siviglia</i> in Italian, is one of the more purely entertaining evenings to be had in an opera house. No dying; no historical indignities; no goddesses or gods deciding the fate of humanity. There isn’t even any (actual) disease or (genuine) heartbreak or (mean-spirited) deception. It is an archetype of <i>opera buffa</i>—from the Italian <i>buffo</i>, meaning funny or amusing or, in musical terms, comic—where everyone, even the antagonist, ends up smiling and singing together.</p>

          
          <div id="ezoic-pub-ad-placeholder-102"> </div>

          <p>And the singing! From high to low, every voice must be capable of rapid patter singing, vocal ornamentation, and effortless agility. The score features one catchy melody after another, starting right off the bat with Figaro, the titular barber, and his entry aria that even first-time operagoers will know from pop culture. There’s even a brief “storm” in the second act, an element of the natural world that Rossini excelled at depicting musically.</p>

          <ImageFull>
            <StaticImage src="../../images/operas/barbiere/MET-Opera-Il-Barbiere-di-Siviglia.jpeg" alt="The Barber of Seville gets some help from Rosina preparing Doctor Bartolo for a shave, in Bartlett Sher’s Metropolitan Opera production &copy; Ken Howard/Metropolitan Opera" title="The Barber of Seville gets some help from Rosina preparing Doctor Bartolo for a shave, in Bartlett Sher’s Metropolitan Opera production &copy; Ken Howard/Metropolitan Opera"/>
            <figcaption><i>The Barber of Seville</i> gets some help from Rosina preparing Doctor Bartolo for a shave, in Bartlett Sher’s Metropolitan Opera production &copy; Ken Howard/Metropolitan Opera</figcaption>
          </ImageFull>

          <p>So leave all your cares at home, and prepare to be humming the tunes from this perennial favourite long after the curtain falls.</p>

          <Header title="In Brief"/>

          <InBrief content={inbrief}/>

          </MainBodySection>

  
          <div id="ezoic-pub-ad-placeholder-103"> </div>


          <AdContainer>
            <AdBanner
              style={{ display: 'block' }}
              slot="3461570696"
              format="auto"
              responsive="true"
            />
          </AdContainer>

          <MainBodySection>

            <Header title="Characters"/>

            <CharacterGrid rows={characters}/>

            <Header title="Synopsis"/>

            <SynopsisAct>Act I – Running Time: 100 mins</SynopsisAct>

            <p>The Count is setting up beneath Rosina’s window to serenade her. His opening aria, “<i>Ecco ridente in cielo</i>”, fails to deliver instantaneous results and, frustrated, he pays the accompanying musicians and shoos them away. His solitude is short-lived, however, as Figaro arrives with one of the more famous tunes in all opera—which you may mistakenly believe is named after him—the self-referential tour de force “<i>Largo al factotum</i>”.</p>
            
            <VideoPlayer src="https://www.youtube.com/embed/kZ5zw3CEL28?autoplay=1" id="kZ5zw3CEL28" title="Peter Mattei as the titular factotum at the Metropolitan Opera in 2011–12"/>

            <p>The jack-of-all-trades reels off the many services he offers—trims, wigs, shaves, tooth-pulling, etc.—for children, ladies, and gentlemen of the city, all the while espousing what great pleasure it brings a man of his quality. Figaro is not lacking in self-esteem! He even demonstrates how chaotic it is like when everyone is calling his name. It’s early in the opera, but a real chance for the right baritone to shine very brightly.</p>
            
            <VideoPlayer src="https://www.youtube.com/embed/zwg6SFAsn9s?autoplay=1" id="zwg6SFAsn9s" title="The Figaro of the great Italian baritone Leo Nucci, then in his early 60s, in Parma in 2005."/>

            <VideoPlayer src="https://www.youtube.com/embed/Y1L_kpZu1XY?autoplay=1" id="Y1L_kpZu1XY" title="And here is Nucci amping up the style in an incredibly rare (for Italy) encore from that same performance. The Italian audience demands it by crying “bis”, which means seconds."/>
            
            <p>His introduction completed, Figaro and the Count recognize each other. Figaro is just about to greet Almaviva by name when the Count says he is travelling in disguise. He does, however, want Figaro’s help wooing Rosina, whom he believes is the daughter of Doctor Bartolo. Figaro clarifies the Doctor who lives in <i>this</i> house right here, and when the Count says yes, the Barber tells him he’s in luck, using an Italian idiom that may attract English ears—“<i>sui maccheroni, il cacio v'è cascato</i>”, meaning essentially “the cheese just fell right on your macaroni!”</p>

          </MainBodySection>

          <div id="ezoic-pub-ad-placeholder-104"> </div>

          <AdContainer>
            <AdBanner
              style={{ display: 'block' }}
              slot="3461570696"
              format="auto"
              responsive="true"
            />
          </AdContainer>

          <MainBodySection>            

            <p>The reason for the Count’s good fortune, Figaro explains, is that he, Figaro, is “barber, surgeon, botanist, apothecary” and more for this house. In short, he runs the place. And even better, the woman the Count is in love with is not Bartolo’s daughter, but his ward.</p>
            
            <p>Rosina comes to the balcony with a note she has written to the man who serenaded her earlier. However, Bartolo tries to hurry her back inside, saying he has to go out and that Rosina should let no one in the house. He also mentions that he plans to marry her, which no one but him wants to happen.</p>

            <p>Finding a free moment, Rosina manages to drop the note before going inside, and the Count, encouraged by Figaro, agrees to sing her another song, introducing himself as the poor student Lindoro. Rosina urges “Lindoro” to continue, but eventually, a servant closes the windows.</p>

            <p>Figaro and the Count decide they have to get inside. After the Count promises to pay Figaro in gold, they sing the joyous duet “<i>All’idea di quel metallo</i>”, where Figaro explains the effect gold has on his devotion and creativity. He suggests the Count disguise himself as a soldier seeking lodging at the Doctor’s house, but he must pretend to be “<i>ubbriaco</i>”, or drunk, because, well, because more hijinks that way. The plan set, the Count asks Figaro where he can find him, and the Barber tells him—No. 15 on the left, five wigs in the window—and the two men dream of their rewards, both financial and amorous, in a meld of Figaro’s rapid staccato patter and the Count’s long legato lines. It is the first of a succession of dazzling conclusions to every scene in the opera.</p>

            <VideoPlayer src="https://www.youtube.com/embed/rkn5Nz5jUAg?autoplay=1" id="rkn5Nz5jUAg" title="Hermann Prey as Figaro and Luigi Alva as the Count in a 1974 film of a La Scala staging, with Claudio Abbado conducting."/>
            
            <p>The second scene begins with Rosina’s star turn aria “<i>Una voce poco fa</i>”, detailing how she intends to win the heart of Lindoro. She is very loving and respectful, she sings, but—the curt Italian “<i>ma</i>—if she is crossed, she is a viper who will make a man earn her love.</p>

            <VideoPlayer src="https://www.youtube.com/embed/TCUNvCjLrTo?autoplay=1" id="TCUNvCjLrTo" title="Italian mezzo Cecilia Bartoli with Rosina’s “Una voce poco fa” from Germany in 1988."/>
            
          </MainBodySection>

          <div id="ezoic-pub-ad-placeholder-105"> </div>

          <AdContainer>
            <AdBanner
              style={{ display: 'block' }}
              slot="3461570696"
              format="auto"
              responsive="true"
            />
          </AdContainer>

          <MainBodySection>            

            <p>Figaro has snuck into the house and meets with Rosina. They discuss how she feels trapped in a tomb and are about to dish about Lindoro when Bartolo approaches. Figaro hides, and Bartolo asks Rosina about their conversation. Annoyed, she leaves, and Bartolo revels in the fact that the more he loves her, the more she dislikes him. Don Basilio, Rosina’s music teacher and Bartolo’s marriage broker, shows up and advises the Doctor to slander the Count to get him out of town. “<i>La calunnia</i>” explains how calumny starts as a little whisper and then slowly gathers force as the rumour spreads until it explodes with the power of a cannon! Rossini brilliantly uses the orchestra to emphasize the lyrics and hint at the storm that will arrive in force in the second act.</p>

            <VideoPlayer src="https://www.youtube.com/embed/AFWRaKrmovY?autoplay=1" id="AFWRaKrmovY" title="A deliciously calculating Ferruccio Furlanetto delivers Basilio’s lecture about “La calunnia” from Glyndebourne in 1981."/>
            
            <p>After the elaborate performance, Bartolo dismisses the plan with a deadpan “no”. He wants to do things his way, marry Rosina, and then everything will work out. He and Basilio retire to draw up the marriage contract. Figaro emerges, having overheard the whole thing. He seeks out Rosina and tells her about Bartolo’s plan, then teases her about his knowledge of a young suitor in town who is in love. When he spells out her name, they begin another fireworks-filled duet, “<i>Dunque io son</i>”. When Rosina reveals that she has already written Lindoro a letter, Figaro realizes that this woman woefully overmatches him!</p>

            <VideoPlayer src="https://www.youtube.com/embed/ibAV_BSSpOI?start=128&autoplay=1" id="ibAV_BSSpOI" title="Catherine Trottmann as Rosina and Florian Sempey as Figaro at the Théâtre des Champs Elysées in 2017."/>
            
            <p>All that remains before the madcap act finale is for Doctor Bartolo to take his solo, “<i>A un dottor della mia sorte</i>”. It is the Doctor’s effort to man-splain Rosina that he is much too smart to fall for her feminine deception. As evident from the numerous live recordings, it is a tough two-part solo with a sprightly cavatina that often leaves Bartolos breathless and feigning in-character heart attacks or other maladies to allow them to IRL catch their breath.</p>

            <VideoPlayer src="https://www.youtube.com/embed/u8UquOt7hJ8?autoplay=1" id="u8UquOt7hJ8" title="Enzo Bara, from the Met in 1989, advising Kathleen Battle not to mess with a doctor of his stature."/>
            
            <p>The remaining 20-plus minutes of Act I steadily build from Almaviva’s entry as a drunken soldier seeking lodging to an ensemble-wide sextet backed by men’s chorus as Bartolo stands frozen like a statue in light of the chaos. In the interim, Bartolo produces his exemption from billeting troops, Rosina bemoans her imprisonment, the drunken Almaviva challenges the Doctor to a duel, Figaro pleads calm while intentionally stirring the pot. Basilio and Berta tell everyone to be quiet. Then the military shows up to arrest everyone. It is all utter <i>opera buffa</i> brilliance.</p>

            <VideoPlayer src="https://www.youtube.com/embed/2JeC26t1brk?autoplay=1" id="2JeC26t1brk" title="The latter part of the Act 1 finale from 1992, with Jennifer Larmore (Rosina), David Malis (Figaro), Richard Croft (Almaviva), Simone Alaimo (Basilio) and Renato Capecchi (Bartolo) and the Netherlands Chamber Orchestra led by Alberto Zedda."/>

            <VideoPlayer src="https://www.youtube.com/embed/TeTUKffXtjc?autoplay=1" id="TeTUKffXtjc" title="During David Letterman’s 33 years hosting late-night television, a couple of opera singers appeared, but opera was only sung once, this bit from the Act 1 finale featuring the cast of the Metropolitan Opera’s new production in 2006."/>
            
          </MainBodySection>

          <div id="ezoic-pub-ad-placeholder-106"> </div>

          <AdContainer>
            <AdBanner
              style={{ display: 'block' }}
              slot="3461570696"
              format="auto"
              responsive="true"
            />
          </AdContainer>

          <MainBodySection>

            <SynopsisAct>Act II - Running Time: 70 mins</SynopsisAct>
            
            <p>Doctor Bartolo is still on edge. Almaviva arrives disguised as Don Alonso, Rosina’s substitute music teacher, because Don Basilio is “sick”. The antagonists begin the brief but masterful comic duet “<i>Pace e gioia</i>” that ends with Bartolo ready to crack up again.</p>

            <VideoPlayer src="https://www.youtube.com/embed/PH7si63Qz-A?autoplay=1" id="PH7si63Qz-A" title="Javier Camarena as Count Almaviva as Don Alonso and Stefano de Peppo as Doctor Bartolo from the Palacio de Bellas Artes in Mexico City, 2012."/>
            
            <p>To convince the suspicious Bartolo, Don Alonso lets him in on a “secret”: he has a note from the Count for Rosina, and he will use the music lesson to show her what a cad the Count is. Bartolo buys it and gets Rosina for her lesson.</p>
            
            <p>Rosina and the Count, whom she recognizes as her dreamboat, the student Lindoro, begin their lesson. When Bartolo falls asleep, the Count assures her they will be together. Bartolo wakes up and interrupts them with his version of Rosina’s aria, which he dedicates to her. Figaro arrives to help distract Bartolo with his regular shave. Bartolo doesn’t want it, but Figaro insists, knowing that he must steal the keys to help them break Rosina out later that night.</p>

            <VideoPlayer src="https://www.youtube.com/embed/HHH4KjqR9O8?autoplay=1" id="HHH4KjqR9O8" title="Kathleen Battle acing her music lesson at the Met in 1989."/>
            
          </MainBodySection>

          <div id="ezoic-pub-ad-placeholder-107"> </div>

          <AdContainer>
            <AdBanner
              style={{ display: 'block' }}
              slot="3461570696"
              format="auto"
              responsive="true"
            />
          </AdContainer>

          <MainBodySection>

            <p>More trouble arrives in the person of a very hale Basilio, who is convinced/bribed by Almaviva/Rosina/Figaro that he has is yellow like a corpse and in fact, has scarlet fever! The quintet shooing him away is another comedic coup for Rossini.</p>

            <VideoPlayer src="https://www.youtube.com/embed/gU_Ofzx4Vkc?autoplay=1" id="gU_Ofzx4Vkc" title="Raul Giménez (Almaviva), Anna Bonitatibus (Rosina), and Leo Nucci (Figaro) try to get Riccardo Zanellato (Basilio) to leave before Alfonso Antoniozzi (Bartolo) wises up, from Parma."/>
            
            <p>The real music teacher gone, Figaro gets back to shaving Bartolo, and the lovers plot Rosina’s midnight escape, but Bartolo overhears them and unmasks the Count, scuttling the planning for now to yet another invigorating melody as the plotters patter beneath Bartolo’s blustering, and eventually get chased away. Berta wanders in for her only aria, “<i>Il vecchiotto cerca moglie</i>”, about old men and young women, one aquiver, the other crazy. Basilio returns, his disease finito, and tells Bartolo that he’s never heard of Don Alonso, so it must be the Count. Bartolo decides to marry Rosina immediately. He deceives her about Lindoro, who she still doesn’t know is the Count, and she’s heartbroken.</p>
            
            <p>Cue the storm! It’s a great piece of music, utilizing string tremolos to presage the eventual timpani thunder and a wind machine, or aeoliphone, to whip up the breeze on stage. During the tempest, Figaro and the Count sneak into Rosina’s room. She is furious at Lindoro for betraying her to the Count, which causes Almaviva to finally admit that <i>he</i>, Lindoro, is the Count, and he loves her. The ensuing trio has the love birds telling each other how happy they are and Figaro telling anyone who will listen how talented he is for getting them together! Eventually, it goes on a bit too long for Figaro’s liking, however, and he starts to point out that while this is all great, they need to go, <i>presto</i>, really, now! It’s hysterical even if you don’t understand a word they are saying. Suddenly Figaro sees two people with a lantern approaching, and it is time to go, for real, as soon as they sing the wonderful cabaletta “<i>zitti, zitti, piano, piano</i>”.</p>

            <VideoPlayer src="https://www.youtube.com/embed/vvaU1uosQeA?autoplay=1" id="vvaU1uosQeA" title="Joyce DiDonato, Juan Diego Flórez, and Peter Mattei doing the Act II trio from Barber at the Met in 2006."/>
            
            <p>Except the ladder is gone and before they can flee, Bartolo and Basilio arrive with the notary. Instead of the Doctor marrying Rosina, however, the Count does, after briefly bribing (again) and threatening (with two bullets) Basilio.</p>
            
            <p>Sometimes the Count summarizes the story with the showpiece aria “<i>Cessa di più resistere</i>”, but it is often omitted due to its fiendishly difficult vibrato arpeggios. (See the Fun Facts for information about where else in Rossini’s oeuvre the aria can be heard.)</p>

            <VideoPlayer src="https://www.youtube.com/embed/noqTbkhGxwA?autoplay=1" id="noqTbkhGxwA" title="Juan Diego Flórez, again, brings down the house in January 2005 at Teatro Real Madrid."/>
            
            <p>The opera ends, a bit anticlimactically if the audience has just given its all to applauding a stellar “<i>Cessa di più</i>”, with Bartolo admitting his folly and Figaro leading everyone in a brief heartwarming chorus about <i>amore</i>.</p>

          </MainBodySection>

          <div id="ezoic-pub-ad-placeholder-108"> </div>

          <AdContainer>
            <AdBanner
              style={{ display: 'block' }}
              slot="3461570696"
              format="auto"
              responsive="true"
            />
          </AdContainer>

          <MainBodySection>

            <Header title="History"/>

            <p>The composition of <i>Barber</i> is the stuff of operatic legend, particularly given its continued popularity (the ninth most performed opera worldwide since 2004). The mythical version is that Rossini wrote the opera in just 13 days, a myth perpetuated but probably not originated by one of his early biographers. The reality is that it took more than 13 days...but fewer than 21. Three weeks. To compose a work that has endured in popularity for 200 years. Even with repurposing some of the music (see below), it is still an astonishing feat.</p>

            <p>Rossini’s libretto was written by Cesare Sterbini, based on the play of the same name by Pierre-Augustin Beaumarchais. An incredibly popular opera based on that play had already been written in 1782 by Giovanni Paisiello. Rossini wrote to Paisiello asking permission to reuse the source material, which he was given. Still, out of respect and likely a desire not to confuse anyone, he originally titled his opera <i>Almaviva</i>. Rossini’s <i>Barber</i> didn’t get its current name until two months after Paisiello died.</p>

            <p>The premiere took place in Rome, at the Teatro Argentina, on February 20, 1816, with Rossini conducting. Luigi Zamboni sang Figaro, Manuel García (who was reportedly paid more for singing than Rossini was for writing the opera) sang Almaviva, and Geltrude Righetti-Giorgi sang Rosina. Whether it was a Paisiello claque (rabid, unruly fans who lustily boo anyone not <i>theirs</i>) or insufficient rehearsal time, the premiere did not go well. It was so bad that Rossini didn’t show up for the second performance. That show went better, with one account saying some audience members went to Rossini’s house afterwards to applaud him, and it has generally been smooth sailing ever since.</p>

          </MainBodySection>

            <div id="ezoic-pub-ad-placeholder-109"> </div>
  
          <AdContainer>
            <AdBanner
              style={{ display: 'block' }}
              slot="3461570696"
              format="auto"
              responsive="true"
            />
          </AdContainer>

          <MainBodySection>

            <Header title="Fun Facts"/>

            <FunFactsHeader>Barber Bunny</FunFactsHeader>

            <p>Few operas have worked their way into popular culture as deeply as <i>Barber</i>, with both the Simpsons and Seinfeld having episodes inspired by the work. But neither comes close to the eight-minute marvel that is Looney Tunes 1950 “Rabbit of Seville”, staring Bugs as (mainly) Figaro and Elmer as (sort of) Bartolo in a performance of the opera at the Hollywood Bowl in Los Angeles, California. The score is largely drawn from the opera’s overture, but Bugs sometimes adds lyrics (“Welcome to my shop, let me cut your mop, let me shave your crop, dain-tily”). The action covers several highlights from the opera and, cleverly, ends with a cake iced with the message “The Marriage of Figaro”, which is the sequel to the novel and also a very different, but <Link to="/operas/figaro"> extraordinary opera</Link>. You can—and definitely should—watch the cartoon on HBO Max or somewhere else on the internet where it probably/definitely resides.</p>

            <ImageFull>
              <StaticImage src="../../images/operas/barbiere/rabbit-of-seville.jpeg" alt="Barber Bugs subjects Elmer’s Bartolo to multiple indignities in the 1950 Looney Tunes classic “Rabbit of Seville”" title="Barber Bugs subjects Elmer’s Bartolo to multiple indignities in the 1950 Looney Tunes classic “Rabbit of Seville”"/>
              <figcaption>Barber Bugs subjects Elmer’s Bartolo to multiple indignities in the 1950 Looney Tunes classic “<i>Rabbit of Seville</i>”</figcaption>
            </ImageFull>
            
            <FunFactsHeader>Reuse, Reduce, Recycle</FunFactsHeader>

            <p>About that overture: It was written several years before Rossini composed the rest of <i>Barber</i>...for another opera, 1813’s <i>Aureliano in Palmira</i>. But Rossini didn’t stop there. He also used the overture for ANOTHER opera, 1815’s <i>Elisabetta, regina d’Inghilterra</i>. The three operas all premiered in different cities—Milan, Naples, and Rome—so it is unlikely most contemporary audiences would have been aware of the recycling, and today only <i>Barber</i> is performed regularly, but still...Rossini, come on.</p>
            
            <p>And it wasn’t <i>just</i> the overture. <i>Aureliano</i> begins with a chorus number called “<i>Sposa del grande Osiride</i>”, which has the same music as Almaviva’s opening aria in <i>Barber</i>, “<i>Ecco ridente in cielo</i>”. Other pieces of music that either derived from earlier operas or would appear in later Rossini operas, include “<i>Questo cor ben lo comprende</i>” from <i>Elisabetta</i>, which is repurposed in “<i>Una voce poco fa</i>”, and “<i>Non più mesta</i> from 1817’s <i>La Cenerentola</i>, which is “<i>Cessa di più</i>” with different lyrics and sung by the mezzo instead of the tenor.</p>

          </MainBodySection>

            <div id="ezoic-pub-ad-placeholder-110"> </div>
  
          <AdContainer>
            <AdBanner
              style={{ display: 'block' }}
              slot="3461570696"
              format="auto"
              responsive="true"
            />
          </AdContainer>

          <MainBodySection>
            
            <FunFactsHeader>Multiple music lessons</FunFactsHeader>

            <p>According to Rossini’s original score, Rosina sings “<i>Contro un cor</i>” during her music lesson at the beginning of Act II. But throughout the opera's performance history, various other pieces have been performed, usually to allow the singer to strut her stuff. According to Stendhal’s contemporary biography/hagiography of Rossini, it was “traditional in Italy to use the charming little aria “<i>La biondina in gondoletta</i>”, or “<i>The blonde in the gondola</i>”, but this practice eventually became trite. In Paris, Stendhal writes, “<i>Di tanti palpiti</i> from Rossini’s <i>Tancredi</i> was “invariably” substituted.</p>

            <VideoPlayer src="https://www.youtube.com/embed/B-STQtPaAXo?autoplay=1" id="B-STQtPaAXo" title="Vincenzo Capezzuto singing “La biondina in gondoletta”."/>
            
            <p>Beginning with the house premiere in 1883, Rosinas at the Metropolitan Opera in New York chose alternate arias. The debut Rosina, Marcella Sembrich, sang three pieces, including the virtuosic coloratura showpiece “<i>Deh torna mio bene</i>”, which generated “wild applause”, per the <i>New York Times</i>. (That same review also claimed that “without a great Rosina” the opera “would be simply unbearable”, so... ) Starting in 1954 with Roberta Peters, Rosinas at the Met reverted to Rossini’s original aria, with one exception, Marilyn Horne, who interpolated other Rossini arias in the lesson scene during the early 1970s and again in 1989, including an official alternate written by Rossini called “<i>La mia pace, la mia calma</i>”.</p>

            <VideoPlayer src="https://www.youtube.com/embed/9VVPnG1zNFY?autoplay=1" id="9VVPnG1zNFY" title="Marilyn Horne singing Rossini’s alternate aria for the Lesson Scene, “La mia pace, la mia calma”."/>

            <FunFactsHeader>Different Doctor</FunFactsHeader>

            <p>It’s not just Rosina who sometimes sings different arias in <i>Barber</i>. Rossini also wrote an alternate for Doctor Bartolo’s who were quite the sort to be suitably up for “<i>A un dottor della mia sorte</i>”. </p>

            <VideoPlayer src="https://www.youtube.com/embed/VVsLrPXsc7I?autoplay=1" id="VVsLrPXsc7I" title="Elia Fabbian sings Bartolo’s alternate aria in this performance from Vincenza in 2008."/>

            <Header title="Bibliography"/>

            <Bibliography content={bibliography}/>

        </MainBodySection>
    </Layout>
  )
}

export default BarbierePage
